* {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Brown, Avenir Next, Avenir, -apple-system, BlinkMacSystemFont, Roboto, system-font,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}
